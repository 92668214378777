import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import PetCreate from "@/views/PetCreate.vue";
import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import SendEmail from "../views/SendEmail.vue";
import ResetPassword from "../views/ResetPassword.vue";
import Profile from "@/views/Profile.vue";
import store from "/src/store";
import guest from "../middlewares/guest.js";
import auth from "../middlewares/auth.js";
import PetProfile from "@/views/PetProfile.vue";
import RegisterEmailVerify from "@/views/RegisterVerify.vue";
import DocumentsPage from "@/views/pages/my/documents/DocumentsPage.vue";
import Communities from "@/views/pages/communities/Communities.vue";
import HomePage from "@/views/pages/home/HomePage.vue";

function middlewarePipeline(context, middleware, index) {
  const nextMiddleware = middleware[index];

  if (!nextMiddleware) {
    return context.next;
  }

  return () => {
    const nextPipeline = middlewarePipeline(context, middleware, index + 1);

    nextMiddleware({ ...context, next: nextPipeline });
  };
}


const routes = [
  {
    path: "/",
    name: "/",
    component: HomePage,
    meta: {
      title: 'Домашняя страница'
    },
  },
  {
    path: "/my/family/",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      middleware: [auth],
      title: 'Семья',
    }
  },
  {
    path: "/my/documents/",
    name: "MyDocuments",
    component: DocumentsPage,
    meta: {
      middleware: [auth],
      title: 'Документы',
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      middleware: [guest],
      title: 'Вход',
    },
  },
  {
    path: "/register/email-verify",
    name: "RegisterEmailVerify",
    component: RegisterEmailVerify,
    meta: {
      middleware: [guest],
      title: 'Подтверждение регистрации',
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      middleware: [guest],
      title: 'Регистрация',
    },
  },
  {
    path: "/auth/reset-password/start",
    name: "SendEmail",
    component: SendEmail,
    meta: {
      middleware: [guest],
      title: 'Сбросить пароль',
    },
  },
  {
    path: "/auth/reset-password/confirm",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {
      middleware: [guest],
      title: 'Задайте новый пароль',
    },
  },
  {
    path: "/my/profile",
    name: "Profile",
    component: Profile,
    meta: {
      middleware: [auth],
      title: 'Мой профиль',
    },
  },
  {
    path: "/family/:family_id/pets/create",
    name: "PetCreate",
    component: PetCreate,
    meta: {
      middleware: [auth],
      title: 'Добавить питомца',
    },
  },
  {
    path: "/family/pets/:pet_id",
    name: "PetProfile",
    component: PetProfile,
    meta: {
      middleware: [auth],
      title: 'Профиль питомца',
    }
  },
  {
    path: "/communities",
    name: "Communities",
    component: Communities,
    meta: {
      title: 'Сообщества',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from, next) => {
  const context = {
    to,
    from,
    next,
    store,
  };

  if (!to.meta.middleware) {
    next();
    return
  }

  const middleware = to.meta.middleware;

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;

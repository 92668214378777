<template>
  <input
      :id="id"
      ref="fileref"
      type="file"
      class="form-control d-none"
      :name="name"
      @input="$emit('added-file', $event.target.files)"
      hidden="hidden"
  />
</template>

<script>
export default {
  name: "HiddenFileInput",
  props: {
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
  },
  emits: ["added-file"],
  methods: {
    click() {
      this.$refs.fileref.click();
    },
  },
};
</script>

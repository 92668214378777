<template>
  <HiddenFileInput ref="fileinput" @added-file="uploadDocument"></HiddenFileInput>

  <div class="card h-100">
    <div class="card-header pb-0 p-3">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="mb-0">Документы</h6>
        </div>
<!--        <div class="col-6 text-end">-->
<!--          <soft-button color="success" size="sm" variant="outline">Подробнее</soft-button>-->
<!--        </div>-->
      </div>
    </div>

    <div
        class="justify-content-between ps-0 mt-2 mb-2 text-center cursor-pointer button-upload-button"
        @click="loadNew"
    >
      <div class="d-flex flex-column text-center">
        <h6 class="mb-1 text-dark font-weight-bold text-sm text-center">
          <i class="fa fa-upload"></i> Загрузить
        </h6>
      </div>
    </div>

    <div class="card-body p-3 pb-0 mb-0" style="max-height: 350px; overflow-y: auto">
      <ul v-if="items.length > 0" class="list-group">
        <DocumentListItem v-for="item in items" :item="item" v-bind:key="item.id"></DocumentListItem>
      </ul>
      <div v-else class="justify-content-between ps-0 mb-2 border-radius-lg">
        Еще нет загруженных документов.
      </div>
    </div>
  </div>
</template>

<script>
import documentsService from "@/services/documents.service";
import HiddenFileInput from "@/components/inputs/HiddenFileInput.vue";
import storageService from "@/services/storage.service";
import DocumentListItem from "@/views/pages/family/pets/DocumentListItem.vue";

export default {
  name: "DocumentsCard",
  components: {
    DocumentListItem,
    HiddenFileInput,
  },

  props: {
    pet_id: {
      type: String,
    },
  },

  data: function () {
    return {
      items: [],
    }
  },

  mounted() {
    this.loadDocs()
  },

  methods: {
    loadDocs: function () {
      documentsService.listPetDocuments(this.pet_id).then(response => {
        this.items = response.items;
      })
    },

    loadNew: function () {
      this.$refs.fileinput.click()
    },

    async uploadDocument(files) {
      documentsService
          .createUploadPetDocumentToken(this.pet_id)
          .then((response) => this.doUpload(response.token, files[0]))
    },

    doUpload(token, file) {
      storageService.upload(token, file).then(() => this.loadDocs())
    },
  }
};
</script>

<style>
.button-upload-button {
  padding: 10px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.button-upload-button:hover {
  background: #ddd;
}
</style>

<template>
{{ year }}-{{ month }}-{{ day }} {{ hours }}:{{ minutes }}
</template>

<script>
export default {
  name: 'Datetime',

  props: {
    value: {
      type: String,
    }
  },

  computed: {
    date: function () {
      return new Date(this.value);
    },

    year: function () {
      return this.withZero(this.date.getUTCFullYear())
    },

    month: function () {
      return this.withZero(this.date.getUTCMonth() + 1)
    },

    day: function () {
      return this.withZero(this.date.getUTCDate());
    },

    hours: function () {
      return this.withZero(this.date.getUTCHours());
    },

    minutes: function () {
      return this.withZero(this.date.getUTCMinutes());
    }
  },

  methods: {
    withZero(number) {
      return (number < 10 ? '0' + number : number);
    }
  }
}
</script>

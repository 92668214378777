<template>
<span v-if="canRelative">
  {{ relative }}
</span>
  <Datetime :value="this.value" v-else></Datetime>
</template>

<script>
import Datetime from "@/components/Datetime.vue";

export default {
  name: 'DateTimeRelative',
  components: {Datetime},

  props: {
    value: {
      type: String,
      required: true,
    }
  },

  computed: {
    date: function () {
      return new Date(this.value);
    },

    diff: function () {
      const diff = {
        ms: Date.now() - Date.parse(this.value),
      }

      diff.seconds = Math.floor(diff.ms / 1000);
      diff.minutes = Math.floor(diff.seconds / 60);
      diff.hours = Math.floor(diff.minutes / 60);
      diff.days = Math.floor(diff.hours / 24);

      return diff;
    },

    canRelative: function () {
      return this.diff.days < 7;
    },

    relative: function () {
      if (this.diff.seconds < 60) {
        const minutes = this.$tc('minutes', 1)

        return `${minutes} назад`;
      }

      if (this.diff.minutes <= 59) {
        const minutes = this.$tc('minutes', this.diff.minutes)

        return `${minutes} назад`;
      }

      if (this.diff.hours < 24) {
        const hours = this.$tc('hours', this.diff.hours / 24)

        return `${hours/24} назад`
      }

      if (this.diff.days <= 7) {
        const days = this.$tc('days', this.diff.days)

        return `${days} назад`
      }

      return ""
    }
  },
}
</script>

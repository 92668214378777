<template>
  <span v-if="status === 1">
    <i class="fas fa-arrow-up"></i>
  </span>
  <span v-else-if="status === 2">
    <i class="fas fa-arrow-down"></i>
  </span>
</template>

<script>
export default {
  name: 'PetMeasurementStatusIcon',

  props: {
    status: {
      type: Number,
      required: true,
    },
  },
}
</script>

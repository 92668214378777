<template>
  <tr @click="download" class="document-list-item-row cursor-pointer">
    <td>{{ document.readable_name }}</td>
    <td><DocumentExtensionIcon :extension=document.extension></DocumentExtensionIcon>{{ extension }}</td>
    <td>{{ document.category_title }}</td>
    <td>{{ document.pet_name }}</td>
    <td><Datetime :value="document.uploaded_at"></Datetime></td>
  </tr>
</template>
<script>
import Datetime from "@/components/Datetime.vue";
import DocumentExtensionIcon from "@/components/values/DocumentExtensionIcon.vue";
import documentsService from "@/services/documents.service";
import storageService from "@/services/storage.service";

export default {
  name: "DocumentListItem",
  components: {DocumentExtensionIcon, Datetime},

  props: {
    pet_id: {
      type: String,
      required: true,
    },
    document: {
      type: Object,
      required: true,
    },
  },

  computed: {
    extension: function () {
      return this.document.extension.toUpperCase();
    },
  },

  methods: {
    download() {
      const itemId = this.document.id
      const extension = this.document.extension;

      documentsService.createShowPetDocumentToken(this.pet_id, itemId).then(function (response) {
        const token = response.token;

        storageService.show(token, itemId).then(function (response) {
          const blob = new Blob([response.data], { type: response.headers.getContentType() })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = "file." + extension
          link.click()
          URL.revokeObjectURL(link.href)
        })
      })
    },
  },
}
</script>

<style scoped>
.document-list-item-row:hover {
  background: #eee;
  color: #000;
}
</style>

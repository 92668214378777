<template>
  <li>
    <a class="dropdown-item border-radius-md" href="javascript:;">
      <div class="py-1 d-flex">
        <div class="d-flex flex-column justify-content-center">
          <h6 class="mb-1 text-sm font-weight-normal">
            {{ item.subject }}
          </h6>
          <p>
            {{ item.body }}
          </p>
          <p class="mb-0 text-xs text-secondary">
            <i class="fa fa-clock me-1"></i>
            <DateTimeRelative :value="item.created_at"></DateTimeRelative>
          </p>
        </div>
      </div>
    </a>
  </li>
</template>
<script>
import DateTimeRelative from "@/components/values/DateTimeRelative.vue";

export default {
  name: "NotificationItem",
  components: {DateTimeRelative},

  props: {
    item: {
      type: Object,
      required: true,
    },
  },
}
</script>
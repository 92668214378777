<template>
  <div class="container-fluid">
    <section class="py-3">
      <div class="row">
        <div class="text-left col-md-8 me-auto">
          <h5>Персональные сервисы</h5>
        </div>
      </div>
      <div class="mt-2 row mt-lg-4">
        <div class="mb-4 col-lg-4 col-md-6">
          <personal-service-card
            :image="slackLogo"
            title="Документы"
            description="Сохраняйте справки, результаты анализов и другие документы в профиле питомца."
            icon-class="fa-book"
            icon-color="green"
          />
        </div>
        <div class="mb-4 col-lg-4 col-md-6">
          <personal-service-card
            :image="spotifyLogo"
            title="Контроль веса питомца"
            description="Ведите записи о весе питомца и сравнивайте с разными периодами."
            icon-class="fa-balance-scale-left"
            icon-color="blue"
          />
        </div>
        <div class="mb-4 col-lg-4 col-md-6">
          <personal-service-card
            :imageprojects-card="xdLogo"
            title="Семейный доступ"
            description="Приглашайте членов семьи и вместе пользуйтесь нашими сервисами."
            icon-class="fa-users"
            icon-color="orange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PersonalServiceCard from "@/views/pages/home/components/PersonalServiceCard.vue";

export default {
  name: "HomePage",
  components: {
    PersonalServiceCard,
  },
  data() {
    return {
      showMenu: false,
    };
  },

  mounted() {
    this.$store.state.isAbsolute = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
};
</script>

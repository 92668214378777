<template>
  <tr>
    <td>
      <div v-if="changing">
        <input type="text"
               style="border: 1px solid #ccc; padding: 5px; border-radius: 2px; max-width: 80px"
               v-model:="measurement.value"
               v-bind:style="pet_weight_input_size"
        >
        &nbsp;
        <span class="save-weight-button" @click="patchMeasurement(measurement);changing=false">
          <i class="fa fa-check"></i>
        </span>
      </div>
      <span v-else>{{ measurement.value }}</span>
      &nbsp;
    </td>
    <td>
      <PetMeasurementStatusIcon :status="measurement.status"></PetMeasurementStatusIcon>
    </td>
    <td><Datetime :value="measurement.created_at"></Datetime></td>
    <td>
      <div class="dropstart">
        <a
            href="javascript:;"
            class="text-secondary pet-measurement-actions-dots"
            :class="{ show: showMenu }"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            @click="showMenu = !showMenu"
        >
          <i class="fas fa-ellipsis-v"></i>
        </a>
        <ul
            class="px-2 py-3 dropdown-menu dropdown-menu-lg-start"
            :class="{ show: showMenu }"
            aria-labelledby="dropdownMarketingCard"
            v-if="!dropdownDisabled"
        >
          <li>
            <a class="dropdown-item border-radius-md text-secondary">
              Выберите действие
            </a>
          </li>
          <li>
            <a class="dropdown-item border-radius-md" @click="toggleChanging">
              Редактировать
            </a>
          </li>
          <li>
            <a class="dropdown-item border-radius-md" @click="deleteMeasurement">
              Удалить
            </a>
          </li>
        </ul>
      </div>
    </td>
  </tr>
</template>

<script>
import showSwal from "/src/mixins/showSwal.js";
import {defineComponent} from "vue";
import Datetime from "@/components/Datetime.vue";
import PetMeasurementStatusIcon from "@/views/pages/family/pets/PetMeasurementStatusIcon.vue";
import petMeasurementsService from "@/services/petMeasurements.service";

export default defineComponent({
  components: {PetMeasurementStatusIcon, Datetime},

  props: {
    measurement: {
      type: Object,
      required: true,
    },
  },

  emits: ['deleted'],

  data: function () {
    return {
      changing: false,
      showMenu: false,
      dropdownDisabled: false,
    }
  },

  computed: {
    pet_weight_input_size() {
      let len = (this.measurement.value + "").length
      if (!len) {
        len = 2
      }

      len *= 5

      return "width:" + len + "em"
    },
  },

  methods: {
    async patchMeasurement(measurement) {
      try {
        await this.$store.dispatch("petMeasurements/patchMeasurement", {
          id: measurement.id,
          value: parseFloat(measurement.value),
        })

        showSwal.methods.showSwal({
          type: "success",
          message: "Запись успешно обновлена",
        });
      } catch (error) {
        showSwal.methods.showSwal({
          type: "error",
          message: "Что-то пошло не так",
        });
      }
    },

    async deleteMeasurement() {
      this.dropdownDisabled = true;

      await petMeasurementsService
          .deleteMeasurement(this.measurement.id)
          .then(() => {
            this.$emit("deleted");
          })
          .finally(() => {
            this.dropdownDisabled = false;
          })
    },

    toggleChanging() {
      this.changing = !this.changing
    },
  },
})
</script>

<style scoped>
.pet-measurement-actions-dots {
  padding: 5px 10px;
}
.pet-measurement-actions-dots:hover {
  background: #eee;
  border-radius: 5px;
}
</style>

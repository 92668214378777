<template>
  <div
    class="w-auto h-auto collapse navbar-collapse h-100 mt-3"
    id="sidenav-collapse-main"
    style="padding: 5px"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-collapse
            navText="Мой профиль"
            :to="{ name: 'Profile' }"
            icon-class="fas fa-user"
            @click="emitClick"
        ></sidenav-collapse>
      </li>
      <li class="nav-item">
          <sidenav-collapse
              navText="Семья"
              :to="{ name: 'Dashboard' }"
              icon-class="fas fa-paw"
              @click="emitClick"
              v-if="authenticated"
          ></sidenav-collapse>
      </li>
      <li class="nav-item">
          <sidenav-collapse
              navText="Документы"
              :to="{ name: 'MyDocuments' }"
              icon-class="fas fa-file"
              @click="emitClick"
              v-if="authenticated"
          ></sidenav-collapse>
      </li>
    </ul>
    <hr>
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-collapse
            navText="Сервисы"
            :to="{ name: '/' }"
            icon-class="fas fa-home"
            @click="emitClick"
        ></sidenav-collapse>
        <sidenav-collapse
            navText="Сообщества"
            :to="{ name: 'Communities' }"
            icon-class="fas fa-comments"
            @click="emitClick"
        ></sidenav-collapse>
      </li>
    </ul>
  </div>
</template>

<script>
import SidenavCollapse from "./SidenavCollapse.vue";

export default {
  name: "SidenavList",

  components: {
    SidenavCollapse,
    //SidenavCard,
  },

  emits: ["click-on-sidenav"],

  props: {
    cardBg: String,
  },

  computed: {
    authenticated() {
      return !!this.$store.getters["auth/currentUser"];
    },
  },

  data() {
    return {
      title: "Мой питомец",
      controls: "dashboardsExamples",
      isActive: "active",
    };
  },

  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },

    emitClick() {
      this.$emit('click-on-sidenav');
    },
  },
};
</script>

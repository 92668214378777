<template>
  <div class="card">
    <!-- Card header -->
    <div class="pb-0 card-header">
      <div class="d-lg-flex">
        <div>
          <h6 class="mb-0">История изменений веса</h6>
        </div>
<!--        <div class="my-auto mt-4 ms-auto mt-lg-0" v-if="!measurements_failed">-->
<!--          <div class="my-auto ms-auto">-->
<!--            <soft-button color="success" size="sm" variant="outline">Подробнее</soft-button>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>

    <div class="px-0 pb-0 card-body">
      <div class="p-3 pt-0" v-if="measurements_failed">
        История изменений недоступна
      </div>
      <div class="table-responsive measurements-table" v-else>
        <table id="users-list" ref="usersList" class="table table-flush">
          <thead class="thead-light">
          <tr>
            <th>Вес</th>
            <th></th>
            <th>Дата</th>
            <th></th>
          </tr>
          </thead>

          <tbody class="text-sm">
            <PetMeasurementsListItem
                v-for="(measurement, index) in measurements.measurements"
                :measurement="measurement"
                v-bind:key="measurement.id"
                @deleted="removeMeasurement(index)"
            >
            </PetMeasurementsListItem>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import PetMeasurementsListItem from "@/views/pages/family/pets/PetMeasurementsListItem.vue";

export default {
  name: "PetMeasurementsCard",
  components: {PetMeasurementsListItem},

  props: {
    pet_id: {
      type: String,
    },
  },

  data() {
    return {
      measurements: [],
      measurements_failed: false,
    }
  },

  async created() {
    try {
      this.measurements = await this.$store.dispatch("petMeasurements/getMeasurements", this.pet_id);
    } catch (error) {
      this.measurements_failed = true;
    }
  },

  methods: {
    async removeMeasurement(index) {
      this.measurements.measurements.splice(index, 1);
    }
  },
}
</script>

<style>
.measurements-table{
  max-height: 300px;
  overflow-y:scroll;
}
</style>

import authService from "../services/auth.service";

export const localStorageUserKey = "currentUser";

export const auth = {
  namespaced: true,
  actions: {
    async login(_, userData) {
      const user = await authService.login(userData);

      localStorage.setItem(localStorageUserKey, JSON.stringify(user))
    },

    async logout() {
      await authService.logout().finally(() => localStorage.removeItem(localStorageUserKey))
    },

    async register(_, userData) {
      await authService.startRegistration(userData);
    },

    async registerEmailVerify(_, userData) {
      await authService.registrationEmailVerify(userData);
    },

    async startResetPassword(_, email) {
      await authService.startResetPassword(email)
    },

    async checkResetPassword(_, reset) {
      return await authService.checkResetPassword(reset)
    },

    async confirmResetPassword(_, payload) {
      return await authService.confirmResetPassword(payload)
    },

    async changePassword(_, payload) {
      return await authService.changePassword(payload)
    },

    async patchUser(_, payload) {
      await authService.patchUser(payload)

      let user = JSON.parse(localStorage.getItem(localStorageUserKey))

      user.first_name = payload.first_name
      user.last_name = payload.last_name

      localStorage.setItem(localStorageUserKey, JSON.stringify(user))

      return true
    },
  },

  getters: {
    authenticated() {
      return localStorage.getItem(localStorageUserKey);
    },

    currentUser() {
      return JSON.parse(localStorage.getItem(localStorageUserKey))
    },
  },
};

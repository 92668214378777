<template>
  <i class="fas me-1" :class="iconClass" aria-hidden="true"></i>
</template>

<script>
const iconsPerFiletype = {
  pdf: 'fa-file-pdf',
  png: 'fa-file-image',
  jpg: 'fa-file-image',
  jpeg: 'fa-file-image',
  heic: 'fa-file-image',

  doc: 'fa-file-word',
  docx: 'fa-file-word',

  mp4: 'fa-file-video',
  mov: 'fa-file-video',
  avi: 'fa-file-video',

  ppt: 'fa-file-powerpoint',
  pptx: 'fa-file-powerpoint',

  zip: 'fa-file-archive',
  rar: 'fa-file-archive',
}

export default {
  name: "DocumentExtensionIcon",

  props: {
    extension: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: false,
      default: 'lg',
    },
  },

  computed: {
    iconClass: function () {
      const icon = iconsPerFiletype[this.extension]

      if (icon) {
        return icon
      }

      return 'fa-file'
    },

    textClass: function () {
      return `text-${this.size}`
    },

    classes: function () {
      return `${this.iconClass} ${this.textClass}`
    },
  },
}
</script>

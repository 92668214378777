<template>
  <aside
    class="border-0 sidenav navbar navbar-vertical navbar-expand-xs"
    id="sidenav-main"
  >
    <sidenav-list :cardBg="customClass" @click-on-sidenav="emitClickOnSidenav" />
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/logo-ct.png";

export default {
  name: "index",
  components: {
    SidenavList,
  },

  emits: ['click-on-sidenav'],

  props: {
    customClass: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      logo,
    };
  },

  methods: {
    emitClickOnSidenav() {
      this.$emit("click-on-sidenav");
    },
  },
};
</script>

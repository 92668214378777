<template>
  <li
      class="nav-item dropdown d-flex align-items-center pe-3 cursor-pointer"
      @click="clickNotifications"
      id="notificationsDropdownButton"
      data-bs-toggle="dropdown"
      aria-expanded="false"
  >
    <a
        href="#"
        class="p-0 nav-link"
        :class="[
            showMenu ? 'show' : '',
          ]"
    >
      <NotificationIcon :count="itemsCount"></NotificationIcon>
    </a>

  <ul
      class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
      :class="showMenu ? 'show' : ''"
      aria-labelledby="notificationsDropdownButton"
  >
    <div v-if="items.length">
      <NotificationItem v-for="(item, index) in items" :item="item" v-bind:key="item" :class="{'mb-2': index < items.length - 1}"></NotificationItem>
    </div>
    <div v-else>
      <div class="d-flex">
        <div class="py-1 d-flex">
          <div class="d-flex flex-column justify-content-center">
            <div class="dropdown-item">
              Новых уведомлений нет
            </div>
          </div>
        </div>
      </div>
    </div>
  </ul>

  </li>
</template>
<script>
import NotificationItem from "@/views/layout/Navbar/components/NotificationItem.vue";
import notificationsService from "@/services/notifications.service";
import NotificationIcon from "@/views/layout/Navbar/components/NotificationIcon.vue";

const intervalDuration = 5 * 60 * 1000

export default {
  name: 'Notifications',
  components: {NotificationIcon, NotificationItem},

  data() {
    return {
      items: [],
      itemsCount: 0,
      showMenu: false,

      intervalId: null,
    }
  },

  mounted() {
    this.loadNotifications()

    this.intervalId = setInterval(() => this.loadNotifications(), intervalDuration);
  },

  unmounted() {
    clearInterval(this.intervalId)
  },

  methods: {
    loadNotifications() {
      notificationsService.listUnreadNotifications().then(response => {
        this.setItems(response.data.items)
      })
    },

    setItems(items) {
      this.items = items;
      this.itemsCount = items.length;
    },

    resetItemsCount() {
      this.itemsCount = 0
    },

    clickNotifications() {
      this.showMenu = !this.showMenu

      if (this.showMenu) {
        this.readUserNotifications()
      }
    },

    readUserNotifications() {
      if (this.itemsCount === 0) {
        return
      }

      const ids = []
      for (let i = 0; i < this.itemsCount; i++) {
        ids.push(this.items[i].id)
      }

      notificationsService.readUserNotifications(ids).then(() => {
        this.resetItemsCount()
      })
    },
  },
}
</script>
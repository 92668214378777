import axios from "axios";
const API_URL = process.env.VUE_APP_API_BASE_URL + '/web/documents';

export default {
    async listPetDocuments(pet_id) {
        const response = await axios.get(API_URL + `/pet-documents/?pet_id=${pet_id}`);

        return response.data
    },
    async createUploadPetDocumentToken(pet_id) {
        const response = await axios.post(API_URL + `/pet-documents/:createUploadToken`, {
            pet_id: pet_id,
        })

        return response.data
    },
    async createShowPetDocumentToken(pet_id, doc_id) {
        const response = await axios.post(API_URL + `/pet/${pet_id}/${doc_id}/:createShowToken`, {
            pet_id: pet_id,
            document_id: doc_id,
        })

        return response.data
    },
};

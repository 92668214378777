import axios from "axios";

const API_URL = process.env.VUE_APP_API_BASE_URL + '/web/family';
const API_DOCUMENTS_URL = process.env.VUE_APP_API_BASE_URL + '/web/family/my/documents';

export default {
  async getMyFamilies() {
    const response = await axios.get(API_URL + "/my");

    return response.data
  },

  async listFamilyDocuments() {
    return axios.get(API_DOCUMENTS_URL);
  },

  async createPet(pet) {
    const response = await axios.post(API_URL + "/pets", pet);

    return response.data
  },

  async patchPet(pet) {
    const uri = API_URL + `/pets/${pet.id}`

    const response = await axios.patch(uri, pet);

    return response.data
  },

  async getPet(petId) {
    const uri = API_URL + `/pets/${petId}`

    const response = await axios.get(uri);

    return response.data
  },

  async deletePet(petId) {
    const uri = API_URL + `/pets/${petId}`

    const response = await axios.delete(uri);

    return response.data
  },
};

<template>
  <div class="card">
    <div class="p-3 card-body">
      <div class="d-flex">
        <div class="p-2 avatar avatar-lg bg-gradient-dark border-radius-md">
          <PersonalServiceIcon :color="iconColor" :icon="iconClass"></PersonalServiceIcon>
        </div>
        <div class="my-auto ms-3">
          <h6>{{ title }}</h6>
        </div>
        <div class="ms-auto">
          <div class="dropdown" v-if="dropdown.length">
            <button
              id="navbarDropdownMenuLink"
              class="btn btn-link text-secondary ps-0 pe-2"
              :class="{ show: showMenu }"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              @click="showMenu = !showMenu"
            >
              <i class="text-lg fa fa-ellipsis-v"></i>
            </button>
            <div
              class="dropdown-menu dropdown-menu-end me-sm-n4 me-n3"
              :class="{ show: showMenu }"
              aria-labelledby="navbarDropdownMenuLink"
            >
              <a
                v-for="(drop, index) in dropdown"
                :key="index"
                class="dropdown-item border-radius-md"
                :href="drop.route"
                >{{ drop.label }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <p class="mt-3 text-sm">
        {{ description }}
      </p>
    </div>
  </div>
</template>

<script>
import PersonalServiceIcon from "@/views/pages/home/components/PersonalServiceIcon.vue";

export default {
  name: "PersonalServiceCard",
  components: {PersonalServiceIcon},
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    dropdown: {
      type: Array,
      default: () => [],
      label: String,
      route: String,
    },
    iconClass: {
      type: String,
      required: true,
    },
    iconColor: {
      type: String,
      required: true, // green, blue, red
    }
  },

  data() {
    return {
      showMenu: false,
    };
  },
};
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="form-group">
      <div class="alert alert-primary text-white" role="alert">
        my-pet.tech не имеет отношения к данным сообществам.
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Чаты</h5>
              </div>
            </div>
          </div>
            <div class="card-body">
              <div class="row chat-tree" style="padding-left:25px">
                  <ul>
                      <CommunityTreeNode :node="item" v-for="item in items" v-bind:key="item"></CommunityTreeNode>
                  </ul>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import showSwal from "/src/mixins/showSwal.js";
import CommunityTreeNode from "@/views/pages/communities/CommunityTreeNode.vue";

export default {
  name: "Users",
  components: {
      CommunityTreeNode,
  },

  async mounted() {},

  data: function () {
      return {
          "items": [
              {
                  name: "Россия",
                  children: [
                      {
                          name: "Астрахань",
                          children: [
                              {
                                  name: "Собаководы Астрахани",
                                  link: {
                                      icon: "fa-telegram tg-color",
                                      url: "https://t.me/astradogshow",
                                  }
                              }
                          ],
                      },
                      {
                          name: "Воронеж",
                          children: [
                              {
                                  name: "Собачники Воронеж",
                                  link: {
                                      icon: "fa-telegram tg-color",
                                      url: "https://t.me/sobachnikiliski"
                                  },
                              }
                          ],
                      },
                      {
                          name: "Калуга",
                          children: [
                              {
                                  name: "ПОТЕРЯШКИ - Калуга и область",
                                  link: {
                                      icon: "fa-vk vk-color",
                                      url: "https://vk.com/poteryashki_kaluga",
                                  },
                              }
                          ],
                      },
                      {
                          name: "Москва",
                          children: [
                              {
                                  name: "Собачники Тимирязевского, Бутырского и САО",
                                  link: {
                                      icon: "fa-telegram tg-color",
                                      url: "https://t.me/timirdogs",
                                  },
                              },
                              {
                                  name: "Собачники Мещанки",
                                  link: {
                                      icon: "fa-telegram tg-color",
                                      url: "https://t.me/SobachnikiMeshanki",
                                  },
                              }
                          ],
                      },
                      {
                          name: "Нижний Новгород",
                          children: [
                              {
                                  name: "\"Собачники\" Нижнего Новгорода\n",
                                  link: {
                                      icon: "fa-vk vk-color",
                                      url: "https://vk.com/dognino",
                                  },
                              },
                              {
                                  name: "Собачий форум Нижнего Новгорода",
                                  link: {
                                      icon: "fa-vk vk-color",
                                      url: "https://vk.com/club211382943",
                                  },
                              }
                          ],
                      },
                      {
                          name: "Оренбург",
                          children: [
                              {
                                  name: "OrenDog",
                                  link: {
                                      icon: "fa-vk vk-color",
                                      url: "https://vk.com/orendog",
                                  },
                              }
                          ],
                      },
                      {
                          name: "Рязань",
                          children: [
                              {
                                  name: "Собачники Рязани | Собаки",
                                  link: {
                                      icon: "fa-vk vk-color",
                                      url: "https://vk.com/dogsrzn",
                                  },
                              }
                          ],
                      },
                      {
                          name: "Санкт-Петербург",
                          children: [
                              {
                                  name: "Собачники и собаки Санкт-Петербурга",
                                  link: {
                                      icon: "fa-vk vk-color",
                                      url: "https://vk.com/board105321449",
                                  },
                              }
                          ],
                      },
                      {
                          name: "Татарстан",
                          children: [
                              {
                                  name: "Казань",
                                  children: [
                                      {
                                          name: "Собачники города Казань",
                                          link: {
                                              icon: "fa-telegram tg-color",
                                              url: "https://t.me/dogskazan",
                                          },
                                      }
                                  ],
                              }
                          ],
                      },
                      {
                          name: "Тверь",
                          children: [
                              {
                                  name: "Собачники Тверь",
                                  link: {
                                      icon: "fa-vk vk-color",
                                      url: "https://vk.com/club74662834",
                                  },
                              }
                          ],
                      },
                  ],
              },
              {
                  name: "Сербия",
                  children: [
                      {
                          name: "Нови Сад",
                          children: [
                              {
                                  name: "Собачники Нови Сада",
                                  link: {
                                      icon: "fa-telegram tg-color",
                                      url: "https://t.me/dogsns",
                                  }
                              }
                          ],
                      }
                  ],
              }
          ],
      }
  },

  methods: {
    alert() {
      showSwal.methods.showSwal({
        type: "error",
        message: "This is a PRO feature.",
        width: 400,
      });
    },
  },
};
</script>
<style>
.chat-tree{
    --spacing : 1.2rem;
    --radius  : 10px;
}

.chat-tree li{
    display      : block;
    position     : relative;
    padding-left : calc(2 * var(--spacing) - var(--radius));
}

.chat-tree ul{
    margin-left  : calc(var(--radius) - var(--spacing));
    padding-left : 0;
}

.chat-tree ul li{
    border-left : 2px solid #ddd;
}

.chat-tree ul li:last-child{
    border-color : transparent;
}

.chat-tree ul li::before{
    content      : '';
    display      : block;
    position     : absolute;
    top          : calc(var(--spacing) / -2);
    left         : -2px;
    width        : calc(var(--spacing) + 2px);
    height       : calc(var(--spacing) + 1px);
    border       : solid #ddd;
    border-width : 0 0 2px 2px;
}

.chat-tree summary{
    display : block;
    cursor  : pointer;
}

.chat-tree summary::marker,
.chat-tree summary::-webkit-details-marker{
    display : none;
}

.chat-tree summary:focus{
    outline : none;
}

.chat-tree summary:focus-visible{
    outline : 1px dotted #000;
}
</style>

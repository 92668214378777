<template>
  <span v-if="value.years === 0 && value.months === 0">
    {{ $tc('months', 1 ) }}
  </span>
  <span v-else-if="value.years === 0 && value.months > 0">
    {{ $tc('months', value.months ) }}
  </span>
  <span v-else-if="value.years > 0 && value.months === 0">
    {{ $tc('years', value.years ) }}
  </span>
  <span v-else>
    {{ $tc('years', value.years ) }} {{ $tc('months', value.months ) }}
  </span>

</template>

<script>
export default {
  name: "Age",

  props: {
    value: {
      type: Object,
      required: true
    },
  }
}
</script>

<template>
    <div class="card pet-card">
        <div class="p-3 mx-4 text-center card-header d-flex justify-content-center">
            <div
                    :class="
          typeof icon === 'object' ? icon.background : 'bg-gradient-success'
        "
                    class="icon icon-shape icon-lg shadow text-center border-radius-lg"
            >
                <i
                        class="opacity-10"
                        :class="typeof icon === 'string' ? icon : icon.component"
                        aria-hidden="true"
                        style="font-size:18pt"
                ></i>
            </div>
        </div>
        <div class="p-3 pt-0 text-center card-body">
            <h6 class="mb-0 text-center">
                <router-link
                    :to="{name: 'PetProfile', params: {pet_id: pet.id}}"
                >
                    {{ title }}
                </router-link>
            </h6>
            <div class="text-xl mt-2">{{ description }}</div>
            <div class="text-xl mt-1 pet-weight-action" v-if="weight_changing">
                Вес <input type="text" v-model:="pet.weight" v-bind:style="pet_weight_input_size"> кг
                <span class="save-weight-button" @click="changePetWeight"><i class="fa fa-check"></i></span>
                <span class="close-weight-button" @click="weight_changing=false"><i class="fa fa-close"></i></span>
            </div>
            <div class="text-xl mt-1 pet-weight" v-else @click="weight_changing=true">
                <span>
                    Вес {{ pet.weight }} кг
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import showSwal from "/src/mixins/showSwal.js";

export default {
    name: "DashboardPetCard",
    computed: {
        pet_weight_input_size() {
          let len = (this.pet.weight + "").length
          if (!len) {
              len = 2
          }

          len *= 14

          return "width:" + len + "px"
        },
    },
    data() {
        return {
            "weight_changing": false,
            "last_weight": 0.0,
        }
    },
    methods: {
        async changePetWeight() {
            if (this.last_weight === this.pet.weight) {
              return
            }

            try {
                await this.$store.dispatch("petMeasurements/createWeightMeasurement", {
                    pet_id: this.pet.id,
                    value: parseFloat(this.pet.weight),
                    measured_at: new Date(),
                })

                this.last_weight = this.pet.weight
                this.weight_changing = false

                showSwal.methods.showSwal({
                    type: "success",
                    message: "Вес питомца успешно обновлен",
                });
            } catch (error) {
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Что-то пошло не так",
                });
            }
        },
    },
    props: {
        icon: {
            type: [String, Object],
            required: true,
            component: {
                type: String,
            },
            background: {
                type: String,
            },
            default: () => ({
                background: "bg-white",
            }),
        },
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            default: "",
        },
        value: {
            type: [String, Number],
            default: "",
        },
        pet: {
            name: "pet",
            weight: 5.0,
        },
    },
    mounted() {
      this.last_weight = this.pet.weight
    }
};
</script>

<style>
.pet-card {
  padding-top: 20px;
  padding-bottom: 20px;
}
.pet-weight span {
    border-bottom: 1px dotted #bbb;
    padding: 2px;
}
.save-weight-button {
    color: #2ecc71;
    cursor: pointer;
}
.save-weight-button:hover {
    color: #27ae60;
}
.close-weight-button {
    margin-left: 5px;
    color: #e74c3c;
    cursor: pointer;
}
.close-weight-button:hover {
    color: #c0392b;
}
.pet-weight-action input {
    border:1px solid #7f8c8d;
    border-radius: 5px;
}
</style>

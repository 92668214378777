<template>
  <nav
    class="navbar fixed-top navbar-main navbar-expand-lg navbar-light bg-light"
    style="box-shadow: none;background: #fff;border-bottom: 1px solid #ccc"
    data-scroll="true"
  >
    <i
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
        id="iconSidenav"
    ></i>
    <a class="m-0 navbar-brand" href="/">
      <img :src="logo" class="navbar-brand-img h-100" alt="main_logo" width="32px" height="32px" />
      <span class="ms-1 font-weight-bolder-md">Мой Питомец</span>
    </a>
    &nbsp;&nbsp;
    <a
        href="#"
        @click="toggleSidebar"
        id="iconNavbarSidenav"
    >
      <div class="sidenav-toggler-inner">
        <i class="sidenav-toggler-line"></i>
        <i class="sidenav-toggler-line"></i>
        <i class="sidenav-toggler-line"></i>
      </div>
    </a>

  <div class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
    </ul>
    <div
        class="pe-md-3 d-flex align-items-center ms-md-auto"
    >
    </div>
    <div>
      <ul class="navbar-nav navbar-right my-2 my-lg-0">
      <Notifications v-if="loggedIn"></Notifications>
      <li class="nav-item d-flex align-items-center log-button">
        <router-link
            v-if="!loggedIn"
            :to="{ name: 'Login' }"
            class="px-0 nav-link font-weight-bold"
        >
          Войти
        </router-link>

        <a
            v-else
            class="px-0 nav-link font-weight-bold"
            style="cursor: pointer"
            @click="logoutUser"
        >
          Выйти
        </a>
      </li>
    </ul>
    </div>
</div>

  </nav>


</template>
<script>
import { mapMutations, mapActions } from "vuex";
import logo from "@/assets/img/logo-ct.png";
import Notifications from "@/views/layout/Navbar/components/Notifications.vue";

export default {
  name: "navbar",
  data() {
    return {
      logo,
      showMenu: false,
    };
  },
  props: ["minNav", "textWhite"],
  created() {
    this.minNav;
  },
  methods: {
    ...mapMutations(["navbarMinimize"]),
    ...mapActions(["toggleSidebarColor"]),

    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },

    async logoutUser() {
      try {
        await this.$store.dispatch("auth/logout");
      } finally {
        this.$router.push("/login");
      }
    },
  },
  components: {
    Notifications
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    loggedIn() {
      return this.$store.getters["auth/authenticated"];
    },
  },
  updated() {
    const navbar = document.getElementById("navbarBlur");
    window.addEventListener("scroll", () => {
      if (window.scrollY > 10 && this.$store.state.isNavFixed) {
        navbar.classList.add("blur");
        navbar.classList.add("position-sticky");
        navbar.classList.add("shadow-blur");
      } else {
        navbar.classList.remove("blur");
        navbar.classList.remove("position-sticky");
        navbar.classList.remove("shadow-blur");
      }
    });
  },
};
</script>
<style>
a:hover {
  cursor: pointer;
}
.log-button {
  cursor: pointer;
  background: #e9ecef;
  padding: 0 25px;
  border-radius: 15px;
}
.log-button:hover {
  background: #dee2e6;
}
.navbar-right {
  float:right!important;
}
</style>
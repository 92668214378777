<template>
  <div class="card">
    <div class="card-header pb-0 p-3">
      <h6 class="mb-0">Информация о чипе</h6>
    </div>

    <div class="card-body p-3">
      <div>
        Данные о чипе не найдены. Такое бывает, когда чип был зарегистрирован недавно.
      </div>
    </div>
  </div>
</template>

<template>
  <div class="position-relative">
    <i class="cursor-pointer fa fa-bell"></i>
    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary notification-badge" v-if="count > 0">{{ count }}</span>
  </div>
</template>

<script>
export default {
  name: 'NotificationIcon',

  props: {
    count: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style scoped>
.notification-badge {
  padding: 5px;
  font-size: 0.8rem;
}
</style>

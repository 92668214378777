import axios from "axios";

const API_URL = process.env.VUE_APP_API_BASE_URL + '/web/pet-measurements';

export default {
  async getMeasurements(petId, page) {
    const uri = API_URL + `/measurements?pet_id=${petId}&page=${page}`

    const response = await axios.get(uri);

    return response.data
  },
  async createMeasurement(payload) {
    const uri = API_URL + `/measurements`

    const response = await axios.post(uri, payload);

    return response.data
  },
  async patchMeasurement(id, payload) {
    const uri = API_URL + `/measurements/${id}`

    const response = await axios.patch(uri, payload);

    return response.data
  },
  async deleteMeasurement(id) {
    const uri = API_URL + `/measurements/${id}`;

    const response = await axios.delete(uri);

    return response
  },
};

<template>
  <div class="nav-wrapper position-relative end-0">
    <ul class="nav nav-pills nav-fill p-1" role="tablist">
      <li class="nav-item" v-for="family in families" v-bind:key="family.family.id">
        <a
          class="nav-link mb-0 px-0 py-1 active active"
          data-bs-toggle="tab"
          role="tab"
          aria-selected="true"
          >{{ family.family.title }}</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";

export default {
  name: "family-pill",
  props: {
      families: {
          type: Array,
          default: function () {
              return []
          },
      },
  },
  watch: {
      families: function () {
          setNavPills();
      },
  },
  mounted() {
    setNavPills();
  },
};
</script>

<template>
  <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
    <div class="d-flex flex-column">
      <h6 class="mb-1 text-dark font-weight-bold text-sm">
        {{ name }}
      </h6>
      <span class="text-xs"><Datetime :value="item.uploaded_at"></Datetime></span>
    </div>

    <div class="d-flex align-items-center text-sm">
      <soft-badge color="secondary" variant="gradient" size="sm">{{ item.category_title }}</soft-badge>

      <button class="btn btn-link text-dark text-sm mb-0 px-0 ms-4" title="Скачать" @click="download">
        <DocumentExtensionIcon :extension="item.extension"></DocumentExtensionIcon>
        {{ extension }}
      </button>
    </div>
  </li>
</template>

<script>
import SoftBadge from "@/components/SoftBadge.vue";
import Datetime from "@/components/Datetime.vue";
import documentsService from "@/services/documents.service";
import storageService from "@/services/storage.service";
import DocumentExtensionIcon from "@/components/values/DocumentExtensionIcon.vue";

export default {
  name: 'DocumentListItem',
  components: {Datetime, SoftBadge, DocumentExtensionIcon},

  props: {
    pet_id: {
      type: String,
    },
    item: {
      type: Object,
    },
  },

  computed: {
    extension: function () {
      return this.item.extension.toUpperCase()
    },

    name: function () {
      if (this.item.name.length < 45) {
        return this.item.name
      }

      return this.item.readable_name
    },
  },

  methods: {
    download() {
      const itemId = this.item.id
      const extension = this.item.extension;

      documentsService.createShowPetDocumentToken(this.pet_id, itemId).then(function (response) {
        const token = response.token;

        storageService.show(token, itemId).then(function (response) {
          const blob = new Blob([response.data], { type: response.headers.getContentType() })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = "file." + extension
          link.click()
          URL.revokeObjectURL(link.href)
        })
      })
    },
  },
}
</script>

<template>
  <div id="delete" class="card mt-4">
    <div class="card-header">
      <h5>Удалить питомца</h5>
      <p class="text-sm mb-0"> Будьте внимательны, удаление невозможно отменить </p>
    </div>
    <div class="card-body d-sm-flex pt-0">
      <div class="d-flex align-items-center mb-sm-0 mb-4">
        <div>
          <div class="form-check form-switch">
            <input id="deleteAccount" class="form-check-input" type="checkbox" name="deleteAccount" v-model=confirmed>
            <label class="form-check-label" for="deleteAccount"></label>
          </div>
        </div>
        <div class="ms-2">
          <span class="text-dark font-weight-bold d-block text-sm">Подтверждаю</span>
          <span class="text-xs d-block">Я хочу удалить питомца. &nbsp;</span>
        </div>
      </div>

      <soft-button variant="gradient" color="danger" class="btn-md null null mb-0 ms-auto" :is-disabled="!confirmed" @click="handleDelete">
        Удалить
      </soft-button>
    </div>
  </div>
</template>

<script>
import showSwal from "/src/mixins/showSwal.js";
import SoftButton from "@/components/SoftButton.vue";

export default {
  name: "FamilyPetDeleteForm",
  components: {SoftButton},

  props: {
    pet_id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      confirmed: false,
    }
  },

  methods: {
    async handleDelete() {
      if (!this.confirmed) {
        return
      }

      try {
        await this.$store.dispatch("family/deletePet", this.pet_id);
        showSwal.methods.showSwal({
          type: "success",
          message: "Питомец успешно удален",
          width: 600,
        });

        this.$router.push({
          name: "Dashboard",
        });
      } catch (error) {
        showSwal.methods.showSwal({
          type: "error",
          message: error,
          width: 600,
        });
      }
    },
  },
}
</script>
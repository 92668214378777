import axios from "axios";
const API_URL = process.env.VUE_APP_API_BASE_URL + '/web/notifications/';

export default {
    async listUnreadNotifications() {
        const url = `${API_URL}?only_unread=true`;

        return axios.get(url);
    },

    async readUserNotifications(ids) {
        const url = `${API_URL}:read`;

        return axios.patch(url, {
            ids: ids
        });
    },
};

<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Список документов</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
<!--                <div class="my-auto ms-auto">-->
<!--                  <a @click="alert" class="mb-0 btn bg-gradient-success btn-sm"-->
<!--                  >+&nbsp; Загрузить</a-->
<!--                  >-->
<!--                </div>-->
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <table class="table table-flush">
                <thead class="thead-light">
                <tr>
                  <th>Название</th>
                  <th>Тип</th>
                  <th>Категория</th>
                  <th>Питомец</th>
                  <th>Загружен</th>
                </tr>
                </thead>
                <tbody class="text-sm">
                <DocumentListItem v-for="document in documents"
                                  :pet_id="document.pet_id"
                                  :document="document"
                                  v-bind:key="document.id"
                ></DocumentListItem>
                </tbody>
              </table>
            </div>
          </div>
<!--          <div-->
<!--              class="d-flex justify-content-center justify-content-sm-between flex-wrap"-->
<!--              style="padding: 24px 24px 0px"-->
<!--          >-->
<!--            <div>-->
<!--              <p>Showing 1 to 1 of 1 entries</p>-->
<!--            </div>-->
<!--            <ul class="pagination pagination-success pagination-md">-->
<!--              <li class="page-item prev-page disabled">-->
<!--                <a class="page-link" aria-label="Previous">-->
<!--                  <span aria-hidden="true"-->
<!--                  ><i class="fa fa-angle-left" aria-hidden="true"></i-->
<!--                  ></span>-->
<!--                </a>-->
<!--              </li>-->
<!--              <li class="page-item disabled active">-->
<!--                <a class="page-link" style="color: white">1</a>-->
<!--              </li>-->
<!--              <li class="page-item next-page disabled">-->
<!--                <a class="page-link" aria-label="Next">-->
<!--                  <span aria-hidden="true"-->
<!--                  ><i class="fa fa-angle-right" aria-hidden="true"></i-->
<!--                  ></span>-->
<!--                </a>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DocumentListItem from "@/views/pages/my/documents/DocumentListItem.vue";
import familyService from "@/services/family.service";

export default {
  name: "DocumentsPage",
  components: {
    DocumentListItem
    //BasePagination,
  },
  data() {
    return {
      documents: [],
    };
  },

  created() {
    this.loadDocuments()
  },

  methods: {
    loadDocuments() {
      familyService.listFamilyDocuments().then(response => {
        this.documents = response.data.items;

        console.log(this.items)
      })
    },
  },
};
</script>
<style>
td {
  padding: 12px 24px !important;
}
</style>

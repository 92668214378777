<template>
  <i class="fas service-icon" :class="[icon, colorClass]"></i>
</template>

<script>
const colorClasses = {
  green: 'service-icon-green',
  blue: 'service-icon-blue',
  red: 'service-icon-red',
  orange: 'service-icon-orange',
}

export default {
  name: 'PersonalServiceIcon',

  props: {
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true, // green, blue, red
    }
  },

  computed: {
    colorClass() {
      return colorClasses[this.color];
    },
  },
}
</script>

<style scoped>
.service-icon {
  font-size: 1.5em;
}

.service-icon-green {
  color: #2cb47c;
}

.service-icon-blue {
  color: #36c5f0;
}

.service-icon-red {
  color: #e01e5a;
}

.service-icon-orange {
  color: #ecb42c;
}
</style>

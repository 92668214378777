import axios from "axios";

const API_URL = process.env.VUE_APP_API_BASE_URL + '/web/cheap-finder';

export default {
    async findCheap(cheapNumber) {
        const response = await axios.get(API_URL + `/finder/${cheapNumber}`);

        return response.data
    },
    async findChip(chipNumber) {
        return await axios.get(API_URL + `/finder/${chipNumber}`);
    },
};

<template>
  <div class="card bg-transparent shadow-xl" v-if="chip && chip.pet">
    <div
        class="overflow-hidden position-relative border-radius-xl"
        :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
      }"
    >
      <span class="mask" :class="`bg-gradient-dark`"></span>
      <div class="card-body position-relative z-index-1 p-3">
        Информация о чипе
        <h5 class="text-white mt-4 mb-5">
          {{ chip.country_code }} {{ chip.vendor_code }} {{ chip.pet.code }}
        </h5>
        <div class="d-flex">
          <div class="d-flex">
            <div class="me-4">
              <p class="text-white text-sm opacity-8 mb-0">
                Выдан
              </p>
              <a :href="chip.issuer.url" v-if="chip.issuer && chip.issuer.url" class="chip-issuer-link">
                <h6 class="mb-0">{{ chip.issuer.name }}</h6>
              </a>
              <h6 class="text-white mb-0" v-else-if="chip.issuer">
                {{ chip.issuer.name }}
              </h6>
            </div>
          </div>
          <div
              class="w-20 d-flex align-items-end justify-content-end ms-auto"
          >
            {{ country_emoji }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <ChipNotFoundCard v-else-if="chip_not_found"></ChipNotFoundCard>

  <ChipLoadErrorCard v-else-if="chip_load_error"></ChipLoadErrorCard>
</template>

<script>
import cheapFinderService from "@/services/cheapFinder.service";
import ChipNotFoundCard from "@/views/components/family/pet/ChipNotFoundCard.vue";
import ChipLoadErrorCard from "@/views/components/family/pet/ChipLoadErrorCard.vue";

export default {
  name: "CheapCard",
  components: {ChipLoadErrorCard, ChipNotFoundCard},

  props: {
    cheap_number: {
      type: String,
    },
  },

  data() {
    return {
      chip: {},
      chip_load_error: false,
      chip_not_found: false,
    }
  },

  computed: {
    country_emoji() {
      const codeEmojiMap = {
        '112': '🇧🇾',
        '643': '🇷🇺',
        '803': '🇺🇦',
      }

      return codeEmojiMap[this.chip.country_code]
    },
  },

  created() {
    this.loadChip()
  },

  methods: {
    async loadChip() {
      await cheapFinderService
          .findChip(this.cheap_number)
          .then(response => {
            this.chip = response.data
          })
          .catch(error => {
            if (error.response && error.response.status === 404) {
              this.chip_not_found = true
            } else {
              this.chip_load_error = true;
            }
          })
    },
  },
};
</script>

<style>
.chip-issuer-link h6 {
  color: #fff;
}
.chip-issuer-link:hover h6 {
  color: #ccc;
}
</style>
